import http from "./http-common";

const createEspecialista = async (data, token) =>
{
    let resp = {};

    await http.post('process/createEspecialista', data, { headers: { 'x-access-token': token } })
        .then(response =>
        {
            resp = {
                status: response.data.status,
                message: response.data.message,
                data: response.data.data
            };
        })
        .catch(err =>
        {
            if (err.response)
            {
                resp = {
                    status: err.response.data.status,
                    message: err.response.data.message,
                    data: {}
                };
            }
            else
            {
                resp = {
                    status: 'error',
                    message: 'Erro ao conectar-se ao servidor',
                    data: {}
                };
            }
        });

    return resp;
};

const persistirTelefone = async (data, token) =>
{
    let resp = {};

    await http.post('process/persistirTelefone', data, { headers: { 'x-access-token': token } })
        .then(response =>
        {
            resp = {
                status: response.data.status,
                message: response.data.message
            };
        })
        .catch(err =>
        {
            if (err.response)
            {
                resp = {
                    status: err.response.data.status,
                    message: err.response.data.message
                };
            }
            else
            {
                resp = {
                    status: 'error',
                    message: 'Erro ao conectar-se ao servidor'
                };
            }
        });

    return resp;
};

const persistirExamesPadrao = async (data, token) =>
{
    let resp = {};

    await http.post('process/persistirExamesPadrao', data, { headers: { 'x-access-token': token } })
        .then(response =>
        {
            resp = {
                status: response.data.status,
                message: response.data.message
            };
        })
        .catch(err =>
        {
            if (err.response)
            {
                resp = {
                    status: err.response.data.status,
                    message: err.response.data.message
                };
            }
            else
            {
                resp = {
                    status: 'error',
                    message: 'Erro ao conectar-se ao servidor'
                };
            }
        });

    return resp;
};

const cadastrarExamesPadrao = async (data, token) =>
{
    let resp = {};

    await http.post('process/cadastrarExamesPadrao', data, { headers: { 'x-access-token': token } })
        .then(response =>
        {
            resp = {
                status: response.data.status,
                message: response.data.message,
                data: response.data.data
            };
        })
        .catch(err =>
        {
            if (err.response)
            {
                resp = {
                    status: err.response.data.status,
                    message: err.response.data.message,
                    data: {}
                };
            }
            else
            {
                resp = {
                    status: 'error',
                    message: 'Erro ao conectar-se ao servidor',
                    data: {}
                };
            }
        });

    return resp;
};

const cadastarPaciente = async (data, token) =>
{
    let resp = {};

    await http.post('process/cadastarPaciente', data, { headers: { 'x-access-token': token } })
        .then(response =>
        {
            resp = {
                status: response.data.status,
                message: response.data.message
            };
        })
        .catch(err =>
        {
            if (err.response)
            {
                resp = {
                    status: err.response.data.status,
                    message: err.response.data.message
                };
            }
            else
            {
                resp = {
                    status: 'error',
                    message: 'Erro ao conectar-se ao servidor'
                };
            }
        });

    return resp;
};

const atualizarPaciente = async (data, token) =>
{
    let resp = {};

    await http.post('process/atualizarPaciente', data, { headers: { 'x-access-token': token } })
        .then(response =>
        {
            resp = {
                status: response.data.status,
                message: response.data.message
            };
        })
        .catch(err =>
        {
            if (err.response)
            {
                resp = {
                    status: err.response.data.status,
                    message: err.response.data.message
                };
            }
            else
            {
                resp = {
                    status: 'error',
                    message: 'Erro ao conectar-se ao servidor'
                };
            }
        });

    return resp;
};

const persistirExamesConsultas = async (data, token) =>
{
    let resp = {};

    await http.post('process/persistirExamesConsultas', data, { headers: { 'x-access-token': token } })
        .then(response =>
        {
            resp = {
                status: response.data.status,
                message: response.data.message
            };
        })
        .catch(err =>
        {
            if (err.response)
            {
                resp = {
                    status: err.response.data.status,
                    message: err.response.data.message
                };
            }
            else
            {
                resp = {
                    status: 'error',
                    message: 'Erro ao conectar-se ao servidor'
                };
            }
        });

    return resp;
};

const persistirEixos = async (data, token) =>
{
    let resp = {};

    await http.post('process/persistirEixos', data, { headers: { 'x-access-token': token } })
        .then(response =>
        {
            resp = {
                status: response.data.status,
                message: response.data.message,
                data: response.data.data
            };
        })
        .catch(err =>
        {
            if (err.response)
            {
                resp = {
                    status: err.response.data.status,
                    message: err.response.data.message,
                    data: {}
                };
            }
            else
            {
                resp = {
                    status: 'error',
                    message: 'Erro ao conectar-se ao servidor',
                    data: {}
                };
            }
        });

    return resp;
};

const persistirLaboratorio = async (data, token) =>
{
    let resp = {};

    await http.post('process/persistirLaboratorio', data, { headers: { 'x-access-token': token } })
        .then(response =>
        {
            resp = {
                status: response.data.status,
                message: response.data.message
            };
        })
        .catch(err =>
        {
            if (err.response)
            {
                resp = {
                    status: err.response.data.status,
                    message: err.response.data.message
                };
            }
            else
            {
                resp = {
                    status: 'error',
                    message: 'Erro ao conectar-se ao servidor'
                };
            }
        });

    return resp;
};

const persistirExamesLaboratorio = async (data, token) =>
{
    let resp = {};

    await http.post('process/persistirExameLaboratorio', data, { headers: { 'x-access-token': token } })
        .then(response =>
        {
            resp = {
                status: response.data.status,
                message: response.data.message
            };
        })
        .catch(err =>
        {
            if (err.response)
            {
                resp = {
                    status: err.response.data.status,
                    message: err.response.data.message
                };
            }
            else
            {
                resp = {
                    status: 'error',
                    message: 'Erro ao conectar-se ao servidor'
                };
            }
        });

    return resp;
};

const createPixQrCode = async (data, token) =>
{
    let resp = {};

    await http.post('process/createPixQrCode', data, { headers: { 'x-access-token': token } })
        .then(response =>
        {
            resp = {
                status: response.data.status,
                message: response.data.message,
                data: response.data
            };
        })
        .catch(err =>
        {
            if (err.response)
            {
                resp = {
                    status: err.response.data.status,
                    message: err.response.data.message
                };
            }
            else
            {
                resp = {
                    status: 'error',
                    message: 'Erro ao conectar-se ao servidor'
                };
            }
        });

    return resp;
};

const findAllEixosByEspecialistaId = async (data, token) =>
{
    let resp = {};

    await http.post('process/findAllEixosByEspecialistaId', data, { headers: { 'x-access-token': token } })
        .then(response =>
        {
            resp = {
                status: response.data.status,
                message: response.data.message,
                data: response.data.data
            };
        })
        .catch(err =>
        {
            if (err.response)
            {
                resp = {
                    status: err.response.data.status,
                    message: err.response.data.message
                };
            }
            else
            {
                resp = {
                    status: 'error',
                    message: 'Erro ao conectar-se ao servidor'
                };
            }
        });

    return resp;
};

const feedDashboard = async (token) =>
{
    let resp = {};

    await http.get('process/feedDashboard', { headers: { 'x-access-token': token } })
        .then(response =>
        {
            resp = {
                status: response.data.status,
                message: response.data.message,
                data: response.data.data
            };
        })
        .catch(err =>
        {
            if (err.response)
            {
                resp = {
                    status: err.response.data.status,
                    message: err.response.data.message
                };
            }
            else
            {
                resp = {
                    status: 'error',
                    message: 'Erro ao conectar-se ao servidor'
                };
            }
        });

    return resp;
};

const validarAssinatura = async (data, token) =>
{
    let resp = {};

    await http.post('process/validarAssinatura', data, { headers: { 'x-access-token': token } })
        .then(response =>
        {
            resp = {
                status: response.data.status,
                message: response.data.message,
                data: response.data.data
            };
        })
        .catch(err =>
        {
            if (err.response)
            {
                resp = {
                    status: err.response.data.status,
                    message: err.response.data.message
                };
            }
            else
            {
                resp = {
                    status: 'error',
                    message: 'Erro ao conectar-se ao servidor'
                };
            }
        });

    return resp;
};

const persistirAdminExamesPadrao = async (data, token) =>
{
    let resp = {};

    await http.post('process/persistirAdminExamesPadrao', data, { headers: { 'x-access-token': token } })
        .then(response =>
        {
            resp = {
                status: response.data.status,
                message: response.data.message,
                data: response.data.data
            };
        })
        .catch(err =>
        {
            if (err.response)
            {
                resp = {
                    status: err.response.data.status,
                    message: err.response.data.message
                };
            }
            else
            {
                resp = {
                    status: 'error',
                    message: 'Erro ao conectar-se ao servidor'
                };
            }
        });

    return resp;
};

const esqueciSenha = async (email) =>
{
    let resp = {};

    await http.post('process/esqueciSenha', {email: email})
        .then(response =>
        {
            resp = {
                status: response.data.status,
                message: response.data.message,
                data: response.data.data
            };
        })
        .catch(err =>
        {
            if (err.response)
            {
                resp = {
                    status: err.response.data.status,
                    message: err.response.data.message
                };
            }
            else
            {
                resp = {
                    status: 'error',
                    message: 'Erro ao conectar-se ao servidor'
                };
            }
        });

    return resp;
};

const exportedObject = {
    createEspecialista,
    persistirTelefone,
    persistirExamesPadrao,
    cadastrarExamesPadrao,
    persistirEixos,
    cadastarPaciente,
    atualizarPaciente,
    persistirExamesConsultas,
    persistirLaboratorio,
    persistirExamesLaboratorio,
    createPixQrCode,
    findAllEixosByEspecialistaId,
    feedDashboard,
    validarAssinatura,
    persistirAdminExamesPadrao,
    esqueciSenha
};

export default exportedObject;