import React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';

function OutsideUserButton (props)
{
    const { loading, action, normalText, ...rest } = props;

    return (
        <LoadingButton
            onClick={ action }
            loading={ loading }
            variant="contained"

            color='primary'
            size='large'
            loadingPosition='end'
            endIcon={ <></> }
            { ...rest }
        >
            { normalText }
        </LoadingButton>
    );
};

export default OutsideUserButton;