/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import
{
    Avatar, CssBaseline, Grid, FormControl, InputLabel,
    OutlinedInput, Box, Typography, Container,
} from '@mui/material';

import { useSnackbar } from 'notistack';

import ProcessService from '../../../services/process.service';

import OutsideUserButton from '../../../components/UserButtons/OutsideUserButton';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

function EsqueciSenha ()
{
    let navigate = useNavigate();

    const [userEmail, setUserEmail] = useState();

    const [buttonLoading, setButtonLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleChangeEmail = (event) =>
    {
        setUserEmail((event.target.value).toLowerCase());
    };

    const handleSubmit = async (event) =>
    {
        setButtonLoading(true);

        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const email = data.get('email');

        const response = await ProcessService.esqueciSenha(email)

        enqueueSnackbar(response.message, {
            variant: response.status,
        });

        if (response.status === 'success')
        {
            navigate('/login');

            return;
        }

        setButtonLoading(false);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={ {
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                } }
            >
                <Avatar sx={ { m: 1, bgcolor: 'primary.main' } }>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component='h1' variant='h5'>
                    Enviar recuperação de senha
                </Typography>
                <Box component='form' onSubmit={ handleSubmit } sx={ { mt: 3, width: '100%' } }>
                    <Grid container spacing={ 2 }>
                        <Grid item xs={ 12 }>
                            <FormControl
                                variant='outlined'

                                fullWidth
                                required
                            >
                                <InputLabel htmlFor='email'>E-mail</InputLabel>
                                <OutlinedInput
                                    id='email'
                                    name='email'

                                    type='email'

                                    label='E-mail'
                                    placeholder='Digite um e-mail válido'

                                    value={ userEmail }

                                    onChange={ handleChangeEmail }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <OutsideUserButton
                        loading={ buttonLoading }
                        normalText='Enviar'

                        type='submit'

                        fullWidth

                        sx={ {
                            mt: 3,
                            mb: 2
                        } }
                    />
                    <Grid container justifyContent='flex-end'>
                        <Grid item>
                            <Link to={ '/login' }>
                                Voltar ao login
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export default EsqueciSenha;